// Global Functions
const GRIT = {};

/**
 * Vendors
 */
import 'picturefill';
import 'lazysizes';
import './modules/ls.object-fit';
import './modules/js.cookie';
import './modules/ga-events';
import './modules/validate';
import './modules/maps';
import Glide from '@glidejs/glide'
// import tomSelect from 'tom-select'

GRIT.polyfills = () => {

    /**
     * Polyfill closest
     */
    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }

    if (!Element.prototype.closest) {
        Element.prototype.closest = function (selector) {
            let el = this;

            do {
                if (el.matches(selector)) {
                    return el
                }

                el = el.parentElement || el.parentNode;
            } while (el !== null && el.nodeType === 1);

            return null;
        };
    }

    /**
     * Array.prototype.forEach() polyfill
     * @author Chris Ferdinandi
     * @license MIT
     */
    if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = function (callback, thisArg) {
            thisArg = thisArg || window;
            for (var i = 0; i < this.length; i++) {
                callback.call(thisArg, this[i], i, this);
            }
        };
    }
}


GRIT.global = () => {
    /**
     * Global settings
     */
    // Preload and No-JS
    setTimeout(function () {
        document.documentElement.classList.remove('preload');
        document.documentElement.classList.remove('no-js');
    }, 300);
}


GRIT.accessibility = () => {
    /**
     * Toggle focus on when users are tabbing, and turn them off once
     * they begin clicking again
     */
    const handleFirstTab = (e) => {
        if (e.keyCode === 9) {
            document.body.classList.add('has-tabbing-user');

            window.removeEventListener('keydown', handleFirstTab);
            window.addEventListener('mousedown', handleMouseDownOnce);
        }
    };

    const handleMouseDownOnce = () => {
        document.body.classList.remove('has-tabbing-user');

        window.removeEventListener('mousedown', handleMouseDownOnce);
        window.addEventListener('keydown', handleFirstTab);
    };

    window.addEventListener('keydown', handleFirstTab);
}


GRIT.headerShrink = () => {
    const header = document.querySelector('header.site-header');
    const body = document.querySelector('body');

    window.addEventListener('scroll', function (e) {

        let offset = 0;

        if (window.innerWidth >= 1001) {
            offset += 125
        }

        if (window.scrollY > offset) {
            header.classList.add('is-shrunk');
            body.classList.add('header-shrunk');
        } else {
            header.classList.remove('header--shrunk');
            body.classList.remove('header-shrunk');
        }
    });
};

GRIT.searchToggle = () => {

    const search = document.querySelector('#header-search');

    document.addEventListener('click', function (event) {

        if (!event.target.matches('[data-toggle="header-search"]')) return;

        if (search.getAttribute('aria-hidden') === 'true') {
            event.target.classList.add('is-active');
            search.setAttribute('aria-hidden', 'false');
            search.querySelector('input').focus();
        } else {
            event.target.classList.remove('is-active');
            search.setAttribute('aria-hidden', 'true');
        }

    });

}


GRIT.mobileNav = () => {
    /**
     * Header and navigation hooks
     */
    const nav = document.querySelector('.site-header__panel');
    const navToggle = document.querySelector('#nav-toggle');
    const root = document.documentElement;
    const body = document.body;
    const breakpoint = 1001;

    if (window.innerWidth < breakpoint) {
        nav.setAttribute('aria-hidden', true);
    }

    navToggle.addEventListener('click', () => {
        nav.classList.toggle('is-open');
        navToggle.classList.toggle('is-active');
        document.querySelector('body').classList.toggle('has-open-panel');

        if (!navToggle.classList.contains('is-open')) {
            nav.setAttribute('aria-hidden', true);

            const scrollY = body.style.top;
            body.style.position = '';
            body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
            root.classList.add('smooth-scroll');
        } else {
            nav.setAttribute('aria-hidden', false);

            const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
            body.style.position = 'fixed';
            body.style.top = `-${scrollY}`;
            root.classList.remove('smooth-scroll');
        }
    });

    window.addEventListener('scroll', () => {
        document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    });

    window.addEventListener('resize', () => {
        if (window.innerWidth >= breakpoint && navToggle.classList.contains('is-active')) {
            const click = new Event('click');
            navToggle.dispatchEvent(click);
        }
    });
}

GRIT.modal = () => {

    /**
     * Modal
     */
    document.addEventListener('click', function (e) {

        if (e.target.matches('.modal-open')) {

            let modal = document.getElementById(e.target.getAttribute('data-modal'));
            modal.classList.add('is-visible');

            setTimeout(() => {
                modal.classList.add('fade');
            }, 100);

            modal.setAttribute('aria-modal', true);
        }


        if (e.target.matches('.modal__close')) {

            let modal = e.target.closest('.modal');

            modal.classList.remove('fade');
            setTimeout(() => {
                modal.classList.remove('is-visible');
            }, 300);
            modal.setAttribute('aria-modal', false);
        }

    });

};

GRIT.sliders = () => {

    const bullets = (slider) => {
        let bulletWrapper = slider.querySelector('.glide__bullets');

        if (!bulletWrapper) {
            return;
        }

        let slides = slider.querySelectorAll('.glide__slide');
        let bulletCount = slides.length;

        for (let slideIndex = 0; slideIndex < bulletCount; slideIndex++) {
            const button = document.createElement('button');
            button.className = '[ glide__bullet ]';
            button.className += '[ bg-primary ]';
            button.setAttribute("data-glide-dir", '=' + slideIndex);

            bulletWrapper.appendChild(button)
        }

    }

    const slidersTestimonials = document.querySelectorAll('[data-glide="testimonials"]');

    if (slidersTestimonials.length > 0) {

        for (let i = 0; i < slidersTestimonials.length; i++) {

            let glide = new Glide(slidersTestimonials[i], {
                type: 'carousel',
                startAt: 1,
                perView: 1,
                gap: 25,
                autoplay: 6000,
                peek: 320,
                breakpoints: {
                    1300: {
                        peek: 240,
                    },
                    1100: {
                        peek: 140,
                        gap: 25,
                    },
                    800: {
                        peek: 0,
                        gap: 0,
                    }
                }
            });

            bullets(slidersTestimonials[i]);

            glide.mount();

        }
    }

    const slidersPosts = document.querySelectorAll('[data-glide="posts"]');

    if (slidersPosts.length > 0) {

        for (let i = 0; i < slidersPosts.length; i++) {

            let closest = slidersPosts[i].querySelector('[data-closest]').getAttribute('data-closest');

            let glide = new Glide(slidersPosts[i], {
                type: 'slider',
                startAt: closest - 1,
                perView: 3,
                gap: 20,
                breakpoints: {
                    1100: {
                        perView: 2,
                    },
                    800: {
                        perView: 1,
                    }
                }
            });

            glide.mount();

            // Custom buttons outside of slider
            let buttonPrev = document.querySelector('[data-glide-button="prev"]');
            let buttonNext = document.querySelector('[data-glide-button="next"]');

            buttonNext.addEventListener("click", function () {
                glide.go('>');
            });

            buttonPrev.addEventListener("click", function () {
                glide.go('<');
            });

        }

    }

    const slidersSingle = document.querySelectorAll('[data-glide="single"]');

    if (slidersSingle.length > 0) {

        for (let i = 0; i < slidersSingle.length; i++) {

            let glide = new Glide(slidersSingle[i], {
                type: 'carousel',
                startAt: 1,
                perView: 1,
                autoplay: 6000,
                gap: 0,
            });

            bullets(slidersSingle[i]);

            glide.mount();

        }
    }

    const slidersTwitter = document.querySelectorAll('[data-glide="twitter"]');

    if (slidersTwitter.length > 0) {

        for (let i = 0; i < slidersTwitter.length; i++) {

            let glide = new Glide(slidersTwitter[i], {
                type: 'carousel',
                startAt: 1,
                perView: 2,
                gap: 110,
                autoplay: 6000,
                breakpoints: {
                    800: {
                        perView: 1,
                    }
                }
            });

            bullets(slidersTwitter[i]);

            glide.mount();

            // Custom buttons outside of slider
            let buttonPrev = document.querySelector('[data-glide-button="prev"]');
            let buttonNext = document.querySelector('[data-glide-button="next"]');

            buttonNext.addEventListener("click", function () {
                glide.go('>');
            });

            buttonPrev.addEventListener("click", function () {
                glide.go('<');
            });

        }

    }

};

GRIT.ajax = () => {
    /**
     * Ajax filters
     */
    const ajaxFilters = document.querySelectorAll('[data-ajax="filter"]');
    const ajaxIUpdateArea = document.querySelector('[data-ajax="update"]');

    const serialize = (form) => {

        let serialized = [];

        for (let i = 0; i < form.elements.length; i++) {

            let field = form.elements[i];
            if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;

            if (field.type === 'select-multiple') {
                for (var n = 0; n < field.options.length; n++) {
                    if (!field.options[n].selected) continue;
                    serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.options[n].value));
                }
            } else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
                serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value));
            }
        }

        return serialized.join('&');

    };

    const ajaxFetch = (url) => {

        const xmlhttp = new XMLHttpRequest();

        ajaxIUpdateArea.classList.add('is-loading');

        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState === XMLHttpRequest.DONE) {   // XMLHttpRequest.DONE == 4
                if (xmlhttp.status === 200) {

                    const parser = new DOMParser();
                    const htmlDocument = parser.parseFromString(xmlhttp.response, "text/html");

                    setTimeout(function () {
                        ajaxIUpdateArea.innerHTML = htmlDocument.documentElement.querySelector('[data-ajax="update"]').innerHTML;
                    }, 600);

                    setTimeout(function () {
                        ajaxIUpdateArea.classList.remove('is-loading');
                    }, 600);

                    window.history.pushState('', '', url);

                } else if (xmlhttp.status === 400) {
                    console.warn('There was an error 400');
                } else {
                    console.warn(xmlhttp.status);
                }
            }
        };

        xmlhttp.open('GET', url, true);

        xmlhttp.send();

    };

    document.addEventListener('click', function (e) {

        if (e.target.matches('.c-pagination a')) {
            e.preventDefault();
            ajaxFetch(e.target.getAttribute('href'));
        }

    });

    ajaxFilters.forEach(function (filter) {

        let buttonRefresh = filter.querySelector('[data-ajax="filter"] button');
        buttonRefresh.parentNode.removeChild(buttonRefresh);

        filter.addEventListener('change', function (e) {

            let params = serialize(filter);

            if (params.length > 0) {
                params = filter.getAttribute('action').indexOf('?') > -1 ? '&' + params : '?' + params;
            }

            ajaxFetch(filter.getAttribute('action') + params);

        });
    });
}

GRIT.alert = () => {

    /**
     * Alert
     */
    const alert = document.querySelector('.js-alert');
    const alertClose = document.querySelector('.js-alert-close');

    if (!alert) {
        return;
    }

    alertClose.addEventListener('click', function (event) {
        alert.classList.add('fade-out');
        setTimeout(() => {
            alert.classList.remove('is-visible');
        }, 600);
    });

};

GRIT.cookieNotice = () => {

    /**
     * Cookies notice
     */
    const cookiesNotice = document.querySelector('.cookie-notice');
    const cookiesDismiss = document.querySelectorAll('[data-action="dismiss"]');

    for (let i = 0; i < cookiesDismiss.length; i++) {
        cookiesDismiss[i].addEventListener('click', function (event) {
            Cookies.set('cookieNotice', 'dismissed', {expires: 30});
            cookiesNotice.classList.remove('is-visible');
        });
    }

    if (Cookies.get('cookieNotice') !== 'dismissed') {
        cookiesNotice.classList.add('is-visible');
    }
};

GRIT.helpNotice = () => {

    /**
     * Help notice
     */
    const helpNotice = document.querySelector('.help-notice');
    const helpDismiss = document.querySelector('[data-action="help-dismiss"]');

    if (helpDismiss) {
        helpDismiss.addEventListener('click', function (event) {
            Cookies.set('helpNotice', 'dismissed', {expires: 30});
            helpNotice.classList.remove('is-visible');
        });
    }

    if (Cookies.get('helpNotice') !== 'dismissed') {
        setTimeout(function () {
            helpNotice.classList.add('is-visible');
        }, 10000);
    }
};

GRIT.validation = () => {
    /**
     * Validation
     */
    document.querySelectorAll('form.js-validate').forEach(form => {
        new Validate(form, {
            'ajax': false,
            'recaptchaSiteKey': document.querySelector("meta[name='recaptcha_site_key']").getAttribute('content'),
            'fieldFeedbackSelector': '.js-field-feedback span',
            'alertList': '#flash-list',
        });
    });

    document.querySelectorAll('form.js-validate-no-rc').forEach(form => {
        new Validate(form, {
            'ajax': false,
            'fieldFeedbackSelector': '.js-field-feedback span',
            'alertList': '#flash-list',
        });
    });
}

GRIT.gtag = () => {

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-BTBR6WG7XB');

};

GRIT.navMain = () => {

    const navToggle = (target, item, list) => {

        if (!list.classList.contains('is-active')) {
            item.querySelector('[data-toggle="nav"]').setAttribute('aria-expanded', true);
        } else {
            item.querySelector('[data-toggle="nav"]').setAttribute('aria-expanded', false);
        }

        item.classList.toggle('is-open');
        list.classList.toggle('is-active');

        closeAll(target, item, list);

    };

    const closeAll = (target, item, list) => {

        let navButton = document.querySelector('[data-toggle="nav"]');
        let navList = document.querySelector('.main-menu__sub-list');

        if (navButton !== target) {
            navButton.closest('.main-menu__item').classList.remove('is-open');
            navButton.setAttribute('aria-expanded', false);
        }

        if (navList !== list) {
            navList.classList.remove('is-active');
        }

    };

    document.addEventListener('click', function (event) {

        if (!event.target.matches('[data-toggle="nav"]')) return;

        const navItem = event.target.closest('.main-menu__item');
        const subNavList = navItem.querySelector('.main-menu__sub-list');

        navToggle(event.target, navItem, subNavList);


    }, false);

};

GRIT.tooltips = () => {

    document.addEventListener('click', function (event) {

        if (!event.target.matches('.js-tooltip_trigger')) return;

        const tooltip = event.target.closest('.ui-tooltip');
        const content = tooltip.querySelector('.ui-tooltip__content');

        content.classList.toggle('is-visible');

    }, false);

}

GRIT.toggle = () => {

    function checkToggle(check) {

        let checkedHide = document.getElementById('existing-business');
        let checkedShow = document.getElementById('business-section');

        if (check.checked) {
            checkedShow.classList.remove('is-hidden');
            if (checkedHide) {
                checkedHide.classList.add('is-hidden');
            }
        } else {
            checkedShow.classList.add('is-hidden');
            if (checkedHide) {
                checkedHide.classList.remove('is-hidden');
            }
        }
    }

    // Toggle business section
    let checkbox = document.querySelector('[name="user_new_business"]');

    if (checkbox) {
        checkToggle(checkbox);

        checkbox.addEventListener('change', (event) => {
            checkToggle(checkbox);
        })
    }

}


GRIT.select = () => {

    // const selects = document.querySelectorAll('.js-select');
    //
    // if (selects.length > 0) {
    //
    //     for (let i = 0; i < selects.length; i++) {
    //         const config = {};
    //         let select = new tomSelect(selects[i], config);
    //     }
    //
    // }

    // https://selectize.github.io/selectize.js/
    $('.js-select').selectize();

    // const multiSelects = document.querySelectorAll('.js-multiselect');
    //
    // if (multiSelects.length > 0) {
    //
    //     for (let i = 0; i < multiSelects.length; i++) {
    //         const config = {
    //             plugins: ['remove_button'],
    //         };
    //         let select = new tomSelect(multiSelects[i], config);
    //     }
    //
    // }

    $('.js-multiselect').selectize({
        plugins: ['remove_button'],
    });

    // const selectAdds = document.querySelectorAll('.js-select-add');
    //
    // if (selectAdds.length > 0) {
    //
    //     for (let i = 0; i < selectAdds.length; i++) {
    //
    //         const config = {
    //             persist: false,
    //             create: true,
    //             createOnBlur: true,
    //             plugins: ['remove_button'],
    //         };
    //         let selectAdd = new tomSelect(selectAdds[i], config);
    //
    //     }
    //
    // }

    $('.js-select-add').selectize({
        plugins: ['remove_button'],
        delimiter: ',',
        persist: false,
        create: function(input) {
            return {
                value: input,
                text: input
            }
        }
    });

}


GRIT.init = () => {
    // Call these functions on load
    GRIT.polyfills();
    GRIT.global();
    GRIT.accessibility();
    GRIT.headerShrink();
    GRIT.navMain();
    GRIT.searchToggle();
    GRIT.mobileNav();
    GRIT.sliders();
    GRIT.modal();
    // GRIT.ajax();
    GRIT.alert();
    GRIT.cookieNotice();
    GRIT.helpNotice();
    GRIT.validation();
    // GRIT.gtag();
    GRIT.tooltips();
    GRIT.toggle();
    GRIT.select();
};

GRIT.init();
