window.initMap = () => {

    const mapStyles = () => {
        return [
            {
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#bdbdbd"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#dadada"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "transit.station",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#c9c9c9"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            }
        ]
    };

    let el = document.querySelector("[data-map='directory']");

    if (!el) {
        return;
    }

    let businesses = JSON.parse(el.getAttribute('data-businesses'));

    const gmap = new google.maps.Map(el, {
        center: new google.maps.LatLng(52.4897, 1.2374),
        zoom: 9,
        streetViewControl: false,
        gestureHandling: 'cooperative',
        styles: mapStyles(),
    });

    let windows = [];
    let markers = [];

    let mapIcon = {
        url: el.getAttribute('data-icon'),
        labelOrigin: new google.maps.Point(15, -10),
    };

    Object.keys(businesses).forEach(function (i, key) {

        const map_lat = parseFloat(businesses[i].map_lat);
        const map_lng = parseFloat(businesses[i].map_lng);

        markers.push(new google.maps.Marker({
            position: {lat: map_lat, lng: map_lng},
            icon: mapIcon
        }));

        if (markers[key]) {
            markers[key].setMap(gmap);

            windows.push(new google.maps.InfoWindow({
                content:
                    `<div class="directory-map__window">
                    <h3>${businesses[i].title}</h3>
                    <a href="${businesses[i].link}">View Profile
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.52 12.21" width="13.52" height="12.21">
                    <rect class="link-arrow-1" x="5.3" y="0.77" width="2" height="12.1" transform="translate(-0.79 12.36) rotate(-89.79)"/>
                    <polygon class="link-arrow-1" points="7.42 12.21 6 10.8 10.69 6.11 6 1.41 7.42 0 13.52 6.11 7.42 12.21"/>
                    </svg>
                    </a>
                 </div>`
            }));

            markers[key].addListener('click', function () {
                windows[key].open(gmap, markers[key]);

                const otherWindows = windows.filter((window, index) => index !== key);
                otherWindows.forEach((window) => {
                    window.close();
                });
            });

        }

    });
}
